import React from "react";
import PropTypes from "prop-types";
import { Row, Button, Upload } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import "./style.scss";
const styleIcon = {
  position: "relative",
  top: "-2px",
  verticalAlign: "middle"
};
const styleFooter = {
  position: "fixed",
  bottom: 0,
  width: "100%",
  display: "flex",
  justifyContent: "flex-end",
  backgroundColor: "#fff",
  padding: "5px 20px",
  boxShadow: "-3px -1px 4px 0 rgb(135 139 222 / 56%)",
  right: "0",
};

const CleFooter = (
  {
    onAdd,
    onModify,
    onDelete,
    onUpload,
    onDownload,
    modifyDisabled = true,
    deleteDisabled = true,
    deleteOnlyDisabled = false,
    onDetail,
    modify = false,
    detail = false,
    upload = false,
    download = false,
    deleteBtn = false,
    add = false,
    reset = false,
    resolve = false,
    filter = false,
    defilter = false,
    search = false,
    purge = false,
    back = false,
    selectAll = false,
    deSelectAll = false,
    downloadProgress = false,
    disabledFooter = false,
    purgeDisabled = false,
    disableSearch = false,
    uploadException = false,
    exportToExcel = false,
    isExportBulkExcel = false,
    onDeSelectAll,
    onSelectAll,
    onReset,
    onSearch,
    onFilter,
    onDefilter,
    onPurge,
    onResolve,
    onBack,
    onUploadException,
    onExportToExcel
  }) => {
  return (
    <Row
      justify={"end"}
      style={styleFooter}
      className={disabledFooter ? "disabledAll" : ""}
    >
      <span>
        {
          uploadException &&
          (
            <Upload customRequest={onUploadException} showUploadList={false}>
              <Button
                type="primary"
                size={"default"}
                className="blackBtn mr10"
                id="sel_result_back"
                icon={
                  <i
                    className="fas fa-cloud-upload-alt mr10"
                    aria-hidden="true"
                  />
                }
              >Upload Exception</Button>
            </Upload>
          )
        }
        {
          exportToExcel
          &&
          (
            <Button
              type="primary"
              size={"default"}
              className="mr10"
              id="sel_result_back"
              disabled={isExportBulkExcel}
              icon={
                isExportBulkExcel ? (<span
                  className="spinner-border spinner-border-sm right10"
                  role="status"
                  aria-hidden="true"
                />) :
                  (<i
                    className="fas fa-cloud-download-alt mr10"
                    aria-hidden="true"
                  />)
              }
              onClick={onExportToExcel} >Export to Excel</Button>
          )
        }

        {back && (
          <Button
            type="primary"
            size={"default"}
            className="blackBtn mr10"
            id="sel_result_back"
            icon={
              <i
                className="fas fa-chevron-circle-left mr10"
                aria-hidden="true"
              />
            }
            onClick={onBack}
          >
            Back
          </Button>
        )}

        {search && (
          <Button
            type="primary"
            size={"default"}
            id={"sel_btn_search"}
            className={"mr10"}
            disabled={disableSearch}
            icon={<i className="fas fa-search mr10" aria-hidden="true" />}
            onClick={onSearch}
          >
            Search
          </Button>
        )}
        {reset && (
          <Button
            type="primary"
            size={"default"}
            id="sel_search_reset"
            className="blackBtn mr10"
            icon={<i className="fas fa-undo-alt mr10" aria-hidden="true" />}
            onClick={onReset}
          >
            Reset
          </Button>
        )}
        {
          resolve && (
            <Button
              type="primary"
              size={"default"}
              className={"secondary mr10"}
              id="sel_result_resolve"
              icon={<i className="fas fa-check-double mr10" aria-hidden="true" />}
              onClick={onResolve}
            >
              Resolve
            </Button>
          )
        }
        {filter && (
          <Button
            type="primary"
            size={"default"}
            className={"mr10"}
            id="sel_result_filter"
            icon={<i className="fas fa-filter mr10" aria-hidden="true" />}
            onClick={onFilter}
          >
            Filter
          </Button>
        )}
        {defilter && (
          <Button
            type="primary"
            size={"default"}
            id="sel_result_defilter"
            className={"mid-black mr10"}
            icon={<i className="fas fa-undo-alt mr10" aria-hidden="true" />}
            onClick={onDefilter}
          >
            De-Filter
          </Button>
        )}
        {purge && (
          <Button
            type="primary"
            size={"default"}
            danger
            className={"mr10"}
            id="sel_result_purge"
            onClick={onPurge}
            disabled={purgeDisabled}
            icon={<i className="fa fa-trash mr10" aria-hidden="true" />}
          >
            Purge
          </Button>
        )}

        {upload && (
          <Upload customRequest={onUpload} showUploadList={false}>
            <Button
              type="primary"
              size={"default"}
              className="blackBtn mr10"
              icon={
                <i
                  className="fas fa-cloud-upload-alt mr10"
                  aria-hidden="true"
                />
              }
            >
              Upload
            </Button>
          </Upload>
        )}
        {download && (
          <Button
            type="primary"
            size={"default"}
            className="mid-black mr10"
            id="sel_form_export_excel"
            onClick={onDownload}
            loading={downloadProgress}
            icon={
              <i
                className="fas fa-cloud-download-alt mr10"
                aria-hidden="true"
              />
            }
          >
            Export
          </Button>
        )}
        {add && (
          <Button
            type="primary"
            size={"default"}
            className="secondary mr10"
            onClick={onAdd}
            icon={
              <i className="fas fa-plus-circle mr10" aria-hidden="true" />
            }
          >
            Add
          </Button>
        )}
        {modify && (
          <Button
            type="primary"
            size={"default"}
            className="mr10 modify-btn"
            disabled={modifyDisabled}
            icon={<i className="far fa-edit mr10" aria-hidden="true" />}
            onClick={onModify}
          >
            Modify
          </Button>
        )}
        {selectAll && (
          <Button
            type="primary"
            size={"default"}
            className="secondary mr10"
            icon={
              <i className="fas fa-check-square mr10" aria-hidden="true" />
            }
            id="sel_result_selectall"
            onClick={onSelectAll}
          >
            Select All
          </Button>
        )}
        {deSelectAll && (
          <Button
            type="primary"
            size={"default"}
            id="sel_result_selectall"
            className="secondary mr10"
            icon={<i className="far fa-square mr10" aria-hidden="true" />}
            onClick={onDeSelectAll}
          >
            De-Select
          </Button>
        )}
        {detail && (
          <Button
            type="primary"
            size={"default"}
            className="mr10 detail-btn"
            id="sel_result_detail"
            disabled={deleteDisabled}
            icon={<InfoCircleOutlined style={styleIcon} />}
            onClick={onDetail}
          >
            Detail
          </Button>
        )}

        {deleteBtn && (
          <Button
            type="primary"
            size={"default"}
            danger
            className="delete-btn"
            disabled={deleteDisabled || deleteOnlyDisabled}
            onClick={onDelete}
            icon={<i className="fa fa-trash mr10" aria-hidden="true" />}
          >
            Delete
          </Button>
        )}

      </span>
    </Row>
  );
};

CleFooter.propTypes = {
  reset: PropTypes.bool,
  filter: PropTypes.bool,
  defilter: PropTypes.bool,
  resolve: PropTypes.bool,
  onAdd: PropTypes.func,
  onModify: PropTypes.func,
  onDelete: PropTypes.func,
  onUpload: PropTypes.func,
  onDownload: PropTypes.func,
  onPurge: PropTypes.func,
  onReset: PropTypes.func,
  onResolve: PropTypes.func,
  onFilter: PropTypes.func,
  onDefilter: PropTypes.func,
  purge: PropTypes.bool,
  modifyDisabled: PropTypes.bool,
  deleteDisabled: PropTypes.bool,
  deleteOnlyDisabled: PropTypes.bool,
  onDetail: PropTypes.func,
  onSearch: PropTypes.func,
  modify: PropTypes.bool,
  detail: PropTypes.bool,
  upload: PropTypes.bool,
  download: PropTypes.bool,
  deleteBtn: PropTypes.bool,
  disabledFooter: PropTypes.bool,
  add: PropTypes.bool,
  search: PropTypes.bool,
  back: PropTypes.bool,
  selectAll: PropTypes.bool,
  deSelectAll: PropTypes.bool,
  downloadProgress: PropTypes.bool,
  purgeDisabled: PropTypes.bool,
  disableSearch: PropTypes.bool,
  uploadException: PropTypes.bool,
  exportToExcel: PropTypes.bool,
  isExportBulkExcel: PropTypes.bool,
  onDeSelectAll: PropTypes.func,
  onSelectAll: PropTypes.func,
  onBack: PropTypes.func,
  onUploadException: PropTypes.func,
  onExportToExcel: PropTypes.func,
};

export default CleFooter;
