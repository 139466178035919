import React, { useState } from "react";
import PropTypes from "prop-types";
import { Layout, Switch } from "antd";
import moment from "moment";
import { useOktaAuth } from '@okta/okta-react';
import { PoweroffOutlined } from "@ant-design/icons";
import { default as momentZone } from "moment-timezone";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import { onUserSignOut } from "../../actions/UserAccess/userAccessActions";
import logo from "../../assets/images/PepsiCo12-alt.png";
import "./Header.scss";

const setInitVal = (isTimeZone, time) => {
  if (isTimeZone) {
    return momentZone(time).tz(window.timeZone);
  } else {
    return moment.utc(time).local();
  }
};

const CLEHeader = ({
  onCollapseChange,
  isTimeZoneDisable,
  timeZone,
  updateTimeZone,
  userAccessInfo,
  hanldeUserInfo,
  // onUserSignOut,
  collapsed,
}) => {
  const { oktaAuth } = useOktaAuth();
  const [time, setTimeZone] = useState(
    setInitVal(timeZone, userAccessInfo.lastLogin)
  );

  const handleToggle = () => {
    if (
      localStorage.getItem("isTimeZoneDefined") === "" ||
      localStorage.getItem("isTimeZoneDefined") === "false"
    ) {
      localStorage.setItem("isTimeZoneDefined", true);
      setTimeZone(setInitVal(true, userAccessInfo.lastLogin));
    } else {
      localStorage.setItem("isTimeZoneDefined", false);
      setTimeZone(setInitVal(false, userAccessInfo.lastLogin));
    }
  };

  const onCollapse = () => {
    setTimeout(() => {
      var elem = document.getElementById("cle-menu");
      elem.scrollTop = 120;
    }, 500);
    onCollapseChange();
  };

  const logout = async () => {
    await oktaAuth.signOut();
    sessionStorage.clear();
    localStorage.clear();
  };

  return (
    <div>
      <div className='text-center subHeaderInfo'>
        <p>
        CLE beta site will be retiring on August 1st. Kindly use <span>https://cle.dev.mypepsico.com/</span>
        </p>
      </div>
      <Layout.Header className={`header fixed cle-new-font`} id="layoutHeader">
        <div>
          <button
            className={`hamburger hamburger--slider flt-left ${collapsed ? "" : "is-active"
              }`}
            style={{ outline: "none", margin: "8px 30px 0px 23px", padding: 0 }}
            type="button"
            onClick={onCollapse}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          {/* <MenuOutlined
          className="header-menu-icon pointer"
          onClick={onCollapseChange}
        /> */}
          <div className="header-cle-logo flt-left">
            <img src={logo} alt="react-logo" />
          </div>
        </div>

        <div className="rightContainer">
          <div>
            <span className={isTimeZoneDisable ? "disableTime" : ""}>
              <span>Local Time</span>
              <Switch
                id="timezone"
                className="toggle-header-menu"
                onChange={(checked) => {
                  handleToggle();
                  updateTimeZone(checked);
                }}
                checked={timeZone}
                disabled={isTimeZoneDisable ? isTimeZoneDisable : false}
              />
              <span>CST</span>
            </span>
            <span className="right-home">
              <Link
                to={{ pathname: "/dashboard", state: "dashBoard" }}
                style={{ verticalAlign: "baseline" }}
              // onClick={() => {
              //   setHeaderName("/dashboard");
              // }}
              >
                <i
                  className="fa fa-home pointer cle-header"
                  title="Dashboard"
                  aria-hidden="true"
                />
              </Link>
            </span>
            <span className="right-user">
              <i
                className="fa fa-user pointer"
                aria-hidden="true"
                onClick={hanldeUserInfo}
              />
            </span>
            <span className="right-logout">
              <PoweroffOutlined
                id="logout"
                className="pointer"
                // onClick={onUserSignOut}
                onClick={logout}
              />
            </span>
          </div>
        </div>
        <div className="time_header" title="Previous Login">
          {userAccessInfo &&
            userAccessInfo.firstName &&
            "Welcome " + userAccessInfo.firstName}
          {userAccessInfo &&
            userAccessInfo.lastLogin &&
            <>
              , &nbsp; &nbsp;
              <i className="far fa-clock" /> &nbsp;
              {moment(time).format("YYYY-MM-DD hh:mm:ss A")}
            </>}
        </div>
      </Layout.Header>
    </div>
  );
};

CLEHeader.propTypes = {
  collapsed: PropTypes.bool,
  hanldeUserInfo: PropTypes.func,
  isTimeZoneDisable: PropTypes.bool,
  onCollapseChange: PropTypes.func,
  timeZone: PropTypes.bool,
  updateTimeZone: PropTypes.func,
  userAccessInfo: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    userAccessInfo: state.userAccess.userAccessInfo,
    timeZone: state.configReducer.isCstTimeZone,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateTimeZone: (val) =>
      dispatch({ type: "UPDATE_TIMEZONE", timeZone: val }),
    timeZoneToggleDisable: () => dispatch({ type: "TIMEZONE_TOGGLE_DISABLE" }),
    // onUserSignOut: () => dispatch(onUserSignOut()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CLEHeader));
